import {
	DataGridPremium,
	DataGridPremiumProps,
	GRID_AGGREGATION_FUNCTIONS,
	GridActionsCellItem,
	GridAggregationFunction,
	GridCellCoordinates,
	GridCellParams,
	GridColDef,
	GridColumnVisibilityModel,
	GridFilterModel,
	GridInitialState,
	GridRowId,
	GridRowModelUpdate,
	GridSlotsComponentsProps,
	GridValidRowModel,
} from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext, useMemo, useState } from 'react'
import RestoreIcon from '@mui/icons-material/Restore'
import { useSelector } from 'react-redux'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _union from 'lodash/union'
import _keys from 'lodash/keys'
import _isEqual from 'lodash/isEqual'
import _reduce from 'lodash/reduce'
import _uniqBy from 'lodash/uniqBy'
import Papa, { ParseResult } from 'papaparse'
import clipboard from 'clipboardy'
import _uniq from 'lodash/uniq'

import { dataGridTables } from 'src/helpers/ui'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { Box, Modal } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

import { showSnackbar } from '../../helpers/snackbarManager'
import {
	ConfirmationResolveType,
	SwitchChainResolveType,
} from '../../hooks/use-confirm-cell-change'

import { CustomColumnMenu } from './column-menu'
import { resolveAnyRowCalculations } from './utils'
import { CustomToolbar } from './toolbar'
import { CustomFooter } from './footer'
import { CustomNoRowsOverlay } from './no-rows'
import { ColumnSettingsModal } from './column-settings/column-settings-modal'
import { useColumnFormatter } from './use-column-formatter'
import { CalculationModal } from './column-settings/calculation-modal/calculation-modal'
import { getLayout } from './questionaire-logic/get-layout'
import { ListOptionsModal } from './list-options-modal'
import { RightClickMenuActions } from './right-click-menu/right-click-menu'
import { COPY_CLIPBOARD, handleSpecialPasteFromClipboard } from './right-click-menu/utils'

type CoreTableProps = {
	rowReordering?: boolean
	hideFooter?: boolean
	onFilterChange?: (filters: GridFilterModel) => void
	onColumnFiltersChange?: (columns: GridColumnVisibilityModel) => void
	isStatic?: boolean
	onCellClick?: (data: { columnId: string; rowId: string; cellId: string }) => void
	selectedCell?: string
	isLocked?: boolean
	styles?: DataGridPremiumProps['sx']
	hideToolbar?: boolean
	hideTable?: boolean
	initialState?: GridInitialState
	hideDensitySelector?: boolean
	hideColumnSelector?: boolean
	hideFilterSelector?: boolean
	changeConfirmation?: {
		Dialog: JSX.Element
		onChangeCallback: (
			rowDifference: string[],
			newRow: GridValidRowModel,
			oldRow: GridValidRowModel,
		) => Promise<SwitchChainResolveType>
	}
	maxHeight: string
}

const slots = {
	toolbar: CustomToolbar,
	columnSortedDescendingIcon: null,
	columnSortedAscendingIcon: null,
	columnMenu: CustomColumnMenu,
	footer: CustomFooter,
	noRowsOverlay: CustomNoRowsOverlay,
	noResultsOverlay: CustomNoRowsOverlay,
}

export const cangoTableAggregationList = [
	...Object.keys(GRID_AGGREGATION_FUNCTIONS).map((agg) => ({
		_id: agg,
		label: agg,
	})),
	{ _id: 'concatenate', label: 'concatenate' },
	{
		_id: 'value',
		label: 'value',
	},
]

export const getRowDifference = (oldRow: any, newRow: any) => {
	const keys = _union(_keys(oldRow), _keys(newRow))

	return _reduce(
		keys,
		(result: string[], key) => {
			if (!_isEqual(oldRow[key], newRow[key])) {
				result.push(key)
			}
			return result
		},
		[],
	)
}

export const concatenateAggregation: GridAggregationFunction<string, string | null> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		const removedEmptyValues = params.values.filter(Boolean)
		const uniqValues = _uniq(removedEmptyValues)
		return uniqValues.join(', ')
	},
	label: 'concatenate',
}

export const valueAggregation: GridAggregationFunction<string, any> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		return params.values[0]
	},
	label: 'value',
}

export const cangoTableAggregations: Record<string, GridAggregationFunction> = {
	...GRID_AGGREGATION_FUNCTIONS,
	concatenate: concatenateAggregation,
	value: valueAggregation,
}

export const CoreTable: ComponentType<CoreTableProps> = ({
	onFilterChange,
	rowReordering = false,
	hideFooter = false,
	onColumnFiltersChange,
	isStatic,
	onCellClick,
	selectedCell,
	isLocked,
	hideToolbar,
	styles,
	initialState,
	hideColumnSelector = false,
	hideDensitySelector = false,
	hideFilterSelector = false,
	hideTable = false,
	changeConfirmation,
	maxHeight,
}) => {
	const {
		table,
		cacheRowUpdate,
		unsavedChanges,
		discardChange,
		isUpdatingTable,
		onRowOrderChange,
		isLoadingTable,
		cacheNewRow,
		saveChanges,
		apiRef,
		sortingModel,
		updateSortingModel,
		onUpdateColumn,
		updateTableConfig,
		cacheMultipleRowUpdates,
		mappedColumns,
		updateMultipleRecords,
	} = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const isBulkEditEnabled = useSelector(persistedConfigSelectors.getIsBulkEditDatabasesEnabled)
	const showAnswersConfig = useSelector(persistedConfigSelectors.getAnswersConfig)
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)
	const [settingsModalId, setSettingsModalId] = useState<string | null>(null)
	const [selectedRow, setSelectedRow] = useState<
		| (TableTypes.Record & {
				columnId: string
				columnType: TableTypes.FieldType
		  })
		| null
	>(null)
	const { columns, contextMenu, setContextMenu, cell } = useColumnFormatter({
		apiRef,
		isTableLocked: !!isLocked,
		isBulkEditEnabled,
		sortingModel,
	})

	const rows = useMemo(() => {
		if (!table?.records) return []
		let resolvedRecords = table.records.map((row) => {
			return resolveAnyRowCalculations({
				row,
				fields: table.fields,
				contacts: mappedContacts,
			})
		})

		if (table.type === TableTypes.TableType.Questionaire) {
			const questionColumnId = table.principal_field ?? ''
			const optionsColumnId =
				table.fields.find((field) => field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''
			const { nodes } = getLayout({ rows: table.records, optionsColumnId, questionColumnId })
			const nodeIds = nodes.map((node) => node.id)
			resolvedRecords = resolvedRecords.sort((a, b) => {
				return nodeIds.indexOf(a._id) - nodeIds.indexOf(b._id)
			})
		} else if (table.row_order) {
			resolvedRecords = resolvedRecords.sort((a, b) => {
				return table.row_order.indexOf(a._id) - table.row_order.indexOf(b._id)
			})
		}

		return _uniqBy(resolvedRecords, '_id')
	}, [table?.records, table?.fields])

	const processRowUpdate: NonNullable<DataGridPremiumProps['processRowUpdate']> = async (
		newRow,
		oldRow,
		params,
	) => {
		const rowDifference = getRowDifference(oldRow, newRow)
		if (changeConfirmation && rowDifference.length) {
			const answer = await changeConfirmation.onChangeCallback(rowDifference, newRow, oldRow)
			if (answer.state === ConfirmationResolveType.Rejected) {
				apiRef.current.setCellSelectionModel({})
				return oldRow
			}
		}

		const cachedValue = cacheRowUpdate({ newRow, oldRow })
		const selectedRows: Map<GridRowId, GridValidRowModel> = apiRef.current.getSelectedRows()

		if (selectedRows.has(newRow._id)) {
			await cacheMultipleRowUpdates({
				updatedRows: Array.from(selectedRows).map(([_selectedRowId, _selectedRow]) => {
					const updatedRow = { ..._selectedRow }
					rowDifference.forEach((rowKey) => {
						updatedRow[rowKey] = newRow[rowKey]
					})
					return { oldRow: _selectedRow, newRow: updatedRow }
				}),
			})
		}

		if (!isBulkEditEnabled) {
			await saveChanges()
		}

		apiRef.current.setCellSelectionModel({})

		setTimeout(() => {
			updateSimilarRows(newRow, oldRow, params)
		}, 500)
		return cachedValue
	}

	const updateSimilarRows: NonNullable<DataGridPremiumProps['processRowUpdate']> = (
		newRow,
		oldRow,
	) => {
		const rowDifference = getRowDifference(oldRow, newRow)
		const rowUpdates: GridRowModelUpdate[] = []
		rowDifference.forEach((rowKey) => {
			const columnType = table?.fields.find((field) => field._id === rowKey)?.type
			if (
				columnType === TableTypes.FieldType.CONTACT &&
				!mappedContacts.has(String(oldRow[rowKey])) &&
				mappedContacts.has(String(newRow[rowKey]))
			) {
				table?.records.forEach((_row) => {
					if (_row._id !== newRow._id && _row.data[rowKey] === oldRow[rowKey]) {
						const _newRow = {
							..._row.data,
							_id: _row._id,
							[rowKey]: newRow[rowKey],
						}
						const cachedRowUpdate = cacheRowUpdate({
							newRow: _newRow,
							oldRow: {
								..._row.data,
								_id: _row._id,
							},
						})
						rowUpdates.push({ id: _row._id, ...cachedRowUpdate })
					}
				})
			}
		})

		if (rowUpdates.length) {
			apiRef.current.updateRows(rowUpdates)
		}
	}

	const processNewRow = (
		newRow: GridValidRowModel,
		calculations: {
			[columnId: string]: TableTypes.FormulaSlice[]
		},
	) => {
		return cacheNewRow(newRow, calculations)
	}

	const handlePasteResults = async (
		results: ParseResult<string[]>,
		originCell: GridCellCoordinates,
	) => {
		const rowsByCell = results.data as string[][]
		const columnId = originCell.field
		const rowId = originCell.id
		if (apiRef.current.getCellMode(rowId, columnId) === 'edit') {
			apiRef.current.stopCellEditMode({
				id: rowId as GridRowId,
				field: columnId ?? '',
				ignoreModifications: true,
			})
		}

		if (!columnId || !rowId) return
		const rowIndex = rows.findIndex((row) => row._id === rowId)
		const columnIndex = columns.findIndex((column) => column.field === columnId)
		if (rowIndex === -1 || columnIndex === -1) return

		const rowUpdates: GridRowModelUpdate[] = []

		for (let i = 0; i < rowsByCell.length; i++) {
			const _row = rowsByCell[i]
			const distanceFromPastingRow = i
			const updatedCells: GridValidRowModel = {}
			for (let j = 0; j < _row.length; j++) {
				const _cell = _row[j]
				const distanceFromPastingColumn = j
				const cellColumn = columns[columnIndex + distanceFromPastingColumn]
				const cangoColumnDef = mappedColumns.get(cellColumn.field)
				if (!cellColumn) return
				let value: any = _cell
				if (cellColumn.type === 'number') {
					value = Number(_cell.replace(/[^0-9.-]+/g, ''))
				} else if (cellColumn.type === 'boolean') {
					value = _cell === 'true'
				} else if (cellColumn.type === 'singleSelect' && cangoColumnDef) {
					value = cangoColumnDef.valueOptions.find((_valOpt) => _valOpt.label === value)?._id
				}

				updatedCells[cellColumn.field] = value
			}

			const dataGridRow = rows[rowIndex + distanceFromPastingRow]
			if (!dataGridRow) {
				const id = v4()
				const newRow = processNewRow({ id, _id: id, ...updatedCells }, {})
				rowUpdates.push({ id: newRow._id, ...newRow })
				return
			}
			const rowCopy = { ...dataGridRow, ...updatedCells }
			const updatedRow = await cacheRowUpdate({ newRow: rowCopy, oldRow: dataGridRow })
			rowUpdates.push({ id: updatedRow._id, ...updatedRow })
		}

		apiRef.current.updateRows(rowUpdates)
	}

	const handlePaste = async (text: string) => {
		const pastedText = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n')

		const selectedCells = apiRef.current.getSelectedCellsAsArray()

		if (!selectedCells.length) return
		const originCell = selectedCells[0]

		Papa.parse(pastedText as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: false,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => handlePasteResults(results, originCell),
		})
	}

	const handleColumnOrderChange = () => {
		const columns = apiRef?.current.getAllColumns() ?? []
		const filteredOfActions = columns.filter((_column) => {
			return !['__reorder__', '__check__', 'Actions'].includes(_column.field)
		})
		updateTableConfig({ column_order: filteredOfActions.map((_column) => _column.field) })
	}

	const columnsWithActions = useMemo<GridColDef[]>(() => {
		if (isStatic) return columns
		let columnsCopy = [...columns]
		if (table?._id && showAnswersConfig[table._id]) {
			const config = showAnswersConfig[table._id]
			columnsCopy = columnsCopy.reduce((acc: GridColDef[], column) => {
				acc.push(column)
				if (config[column.field]) {
					acc.push({
						field: `${column.field}_answer`,
						headerName: 'Answer',
						type: 'string',
						width: 150,
						editable: false,
						disableReorder: true,
						sortable: false,
						pinnable: false,
						filterable: false,
						groupable: false,
						aggregable: false,
						hideable: false,
					})
				}
				return acc
			}, [])
		}
		return isBulkEditEnabled
			? [
					{
						field: 'Actions',
						type: 'actions',
						getActions: ({ id }) => {
							return [
								...(isBulkEditEnabled
									? [
											<GridActionsCellItem
												key={`${id}-restore`}
												icon={<RestoreIcon />}
												label="Discard changes"
												disabled={
													unsavedChanges.unsavedRows[id] === undefined &&
													unsavedChanges.newRows[id] === undefined
												}
												onClick={() => {
													const isNewRow = !!unsavedChanges.newRows[id]
													if (isNewRow) {
														apiRef.current.updateRows([{ id, _id: id, _action: 'delete' }])
													} else {
														apiRef.current.updateRows([
															{ ...unsavedChanges.rowsBeforeChange[id], id },
														])
													}

													discardChange(id as string)
												}}
											/>,
										]
									: []),
							]
						},
					},
					...columnsCopy,
				]
			: columnsCopy
	}, [columns, JSON.stringify(unsavedChanges), showAnswersConfig])

	const memoizedSlotProps = useMemo(
		(): GridSlotsComponentsProps => ({
			toolbar: {
				printOptions: { disableToolbarButton: true },
				csvOptions: { disableToolbarButton: true },
				isStatic,
				isLocked,
				hideToolbar,
				setFilterButtonEl,
				hideColumnSelector,
				hideDensitySelector,
				hideFilterSelector,
			},
			baseCheckbox: {
				size: 'small',
			},
			columnMenu: {
				slotProps: {
					openSettings: setSettingsModalId,
				},
			},
			panel: {
				anchorEl: filterButtonEl,
			},
			loadingOverlay: {
				variant: 'linear-progress',
			},
			cell: {
				onContextMenu: (e: any) => {
					e.preventDefault()
				},
			},
		}),
		[
			isStatic,
			isLocked,
			hideToolbar,
			hideColumnSelector,
			hideDensitySelector,
			hideFilterSelector,
			filterButtonEl,
		],
	)

	return (
		<>
			{!!changeConfirmation && changeConfirmation.Dialog}
			<ColumnSettingsModal onClose={() => setSettingsModalId(null)} columnId={settingsModalId} />
			{!!selectedRow && selectedRow.columnType === TableTypes.FieldType.ROW_CALCULATION && (
				<Modal open={true} onClose={() => setSelectedRow(null)}>
					<CalculationModal
						defaultCalculation={selectedRow.calculations?.[selectedRow.columnId] ?? []}
						columnId={selectedRow.columnId}
						onClose={() => setSelectedRow(null)}
						rowId={selectedRow._id}
					/>
				</Modal>
			)}
			{!!selectedRow && selectedRow.columnType === TableTypes.FieldType.OPTIONS && (
				<ListOptionsModal
					onClose={() => setSelectedRow(null)}
					row={selectedRow}
					columnId={selectedRow.columnId}
				/>
			)}
			<Box
				width="100%"
				height={maxHeight}
				onPaste={async (e) => {
					e.preventDefault()

					const clipboardData = e.clipboardData.getData('text')

					const clipboardDataObject: COPY_CLIPBOARD = JSON.parse(clipboardData)

					if (clipboardDataObject?.type && clipboardDataObject?.data) {
						if (!table) return
						const selectedCellsArray = apiRef.current.getSelectedCellsAsArray()
						const updatedRows = await handleSpecialPasteFromClipboard(
							table.records,
							selectedCellsArray,
						)
						updateMultipleRecords(updatedRows)
						return
					}
					handlePaste(e.clipboardData.getData('text'))
				}}
				display={hideTable ? 'none' : 'flex'}
				flexDirection="column"
			>
				<DataGridPremium
					apiRef={apiRef}
					getRowId={(row) => row._id}
					initialState={initialState}
					rows={rows}
					columns={columnsWithActions}
					density={table?.row_density}
					onDensityChange={(params) => {
						updateTableConfig({ row_density: params })
					}}
					rowReordering={table?.type !== TableTypes.TableType.Questionaire && rowReordering}
					pinnedColumns={{
						left: table?.principal_field ? ['__reorder__', '__check__', table.principal_field] : [],
					}}
					onColumnWidthChange={(params) => {
						onUpdateColumn(params.colDef.field, { width: params.width })
					}}
					onCellKeyDown={async (params, event) => {
						if ((event.metaKey || event.ctrlKey) && ['v', 'c'].includes(event.key)) {
							event.stopPropagation()
							const data = await navigator.clipboard.readText()
							handlePaste(data)
						}
					}}
					hideFooter={hideFooter}
					onRowOrderChange={onRowOrderChange}
					onColumnOrderChange={handleColumnOrderChange}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => {
						showSnackbar(error?.message, { variant: 'error' })
					}}
					checkboxSelection={true}
					sortModel={sortingModel}
					onSortModelChange={(newModel) => updateSortingModel(newModel)}
					onColumnVisibilityModelChange={onColumnFiltersChange}
					disableClipboardPaste
					//@ts-ignore doesn't make sense that this is throwing an error. I cannot seem to fix it.
					sx={{
						...dataGridTables,
						...styles,
						...(hideTable ? { display: 'none' } : {}),
					}}
					showCellVerticalBorder
					showColumnVerticalBorder
					ignoreValueFormatterDuringExport
					disableRowSelectionOnClick
					disableColumnMenu={isStatic}
					getAggregationPosition={() => 'inline'}
					loading={isUpdatingTable || isLoadingTable}
					groupingColDef={{ width: 250 }}
					aggregationFunctions={cangoTableAggregations}
					rowGroupingColumnMode="multiple"
					onFilterModelChange={onFilterChange}
					cellSelection
					onClipboardCopy={(copiedString) => {
						const lines = copiedString.split(/\r?\n/)
						const output = lines.join('\n')
						clipboard.write(output.replace(/"/g, '\\"'))
					}}
					onCellClick={async (params) => {
						if (['group'].includes(params.rowNode.type)) return // prevent error if it's autogenerated column)
						if (params.field === '__check__') return
						if (params.colDef.type === 'boolean') {
							if (isUpdatingTable) {
								return
							}
							const newValue = !params.value
							const cachedUpdate = await processRowUpdate(
								{ ...params.row, [params.field]: newValue },
								params.row,
								{} as any,
							)
							if (cachedUpdate) {
								apiRef.current.updateRows([{ id: params.row._id, ...cachedUpdate }])
							}
							return
						}
						const colType = table?.fields.find((field) => field._id === params.field)?.type
						if (
							colType &&
							[TableTypes.FieldType.VIDEO, TableTypes.FieldType.URL].includes(colType)
						) {
							return
						}
						if (
							colType === TableTypes.FieldType.ROW_CALCULATION ||
							colType === TableTypes.FieldType.OPTIONS
						) {
							const cangoRow = table?.records.find((row) => row._id === params.id)
							setSelectedRow(
								cangoRow ? { ...cangoRow, columnId: params.field, columnType: colType } : null,
							)
							return
						}
						if (onCellClick) {
							onCellClick({
								columnId: params.field,
								rowId: params.row._id,
								cellId: params.id as string,
							})
						}
					}}
					getCellClassName={(params: GridCellParams) => {
						if (params.colDef?.type === 'boolean' && params.isEditable) {
							return 'cell-checkbox'
						}
						if (selectedCell) {
							const [columnId, rowId] = selectedCell.split('-')
							if (params.id === rowId && params.field === columnId) {
								return 'cell--selected'
							}
						}
						const colType = mappedColumns.get(params.field)?.type
						if (colType === TableTypes.FieldType.OPTIONS) {
							return 'cell--options'
						}

						if (colType === TableTypes.FieldType.QUESTIONAIRE_REFERENCE) {
							return 'cell--questionaire-reference'
						}

						return ''
					}}
					getRowClassName={(params) => {
						const unsavedRow = unsavedChanges.unsavedRows[params.id]
						if (unsavedRow) {
							if (unsavedRow._action === 'delete') {
								return 'row--removed'
							}
							return 'row--edited'
						}
						const newRow = unsavedChanges.newRows[params.id]
						if (newRow) {
							return 'row--added'
						}
						return ''
					}}
					slots={slots}
					slotProps={memoizedSlotProps}
				/>
			</Box>
			<RightClickMenuActions
				contextMenu={contextMenu}
				handleClose={() => setContextMenu(null)}
				cellData={cell}
			/>
		</>
	)
}

import { TableTypes } from '@cango-app/types'

import { convertRowValueToArray } from '../utils'
import { Question } from '../../../components/question-flow/types'

export const convertRowValueToQuestionaireOption = (rowValue: any) => {
	const defaultValue: {
		answerType: TableTypes.AnswerType
		options: TableTypes.ListOption[]
	} = {
		answerType: TableTypes.AnswerType.SingleSelect,
		options: [],
	}
	if (!rowValue) {
		return defaultValue
	}

	if (!rowValue['answerType'] || !rowValue['options']) {
		return defaultValue
	}

	return {
		answerType: rowValue['answerType'],
		options: convertRowValueToArray(rowValue['options']),
	}
}

/**
 * Sorts nodes dynamically based on an array of column IDs.
 * @param nodes - Array of nodes to sort.
 * @param sortBy - Array of column IDs to sort by, in hierarchical order.
 * @returns A sorted array of nodes.
 */
export function sortNodesByFields(nodes: Question[], sortBy: string[]): Question[] {
	return [...nodes].sort((a, b) => {
		for (const field of sortBy) {
			const aValue = a.hierarchy[field] || '' // Handle missing values
			const bValue = b.hierarchy[field] || ''
			if (aValue < bValue) return -1
			if (aValue > bValue) return 1
		}
		return 0 // Equal for all fields in sortBy
	})
}

/**
 * Sorts nodes dynamically based on an array of column IDs and places descendants after their parent nodes,
 * ensuring that a question is only asked once per context.
 * @param nodes - Array of nodes to sort.
 * @param sortBy - Array of column IDs to sort by, in hierarchical order.
 * @returns A sorted array of nodes with descendants correctly placed, avoiding duplicate questions in the same context.
 */
export function sortNodesWithUniqueDescendants(nodes: Question[], sortBy: string[]): Question[] {
	const askedQuestions = new Set<string>() // To track asked questions in the same context

	// Helper function to generate a unique key for a node's context
	function getContextKey(node: Question): string {
		return sortBy.map((field) => node.hierarchy[field] || '').join('>')
	}

	// Function to sort nodes and attach descendants
	function attachDescendants(sortedList: Question[], allNodes: Question[]): Question[] {
		const result: Question[] = []

		for (const node of sortedList) {
			const contextKey = getContextKey(node)

			// Skip this node if it has already been asked in this context
			if (askedQuestions.has(`${node._id}:${contextKey}`)) {
				continue
			}

			// Mark this question as asked in the current context
			askedQuestions.add(`${node._id}:${contextKey}`)
			result.push(node) // Add the parent node first

			// Find and sort the descendants of this node
			const descendants = node.descendants
				.map((descendant) => allNodes.find((n) => n._id === descendant._id))
				.filter(Boolean) as Question[] // Ensure only valid descendants

			if (descendants.length > 0) {
				// Sort descendants recursively
				const sortedDescendants = attachDescendants(
					sortNodesByFields(descendants, sortBy),
					allNodes,
				)
				result.push(...sortedDescendants) // Append descendants after the parent
			}
		}

		return result
	}

	// Initial sorting and processing
	const sortedNodes = sortNodesByFields(nodes, sortBy)
	return attachDescendants(sortedNodes, nodes)
}

import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'
import { TableTypes, V3ProjectTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'

import { selectors as authSelectors } from 'src/store/modules/auth'

import { TableContext } from '../../providers/table-provider'
import { getLayout } from '../../modules/tables/questionaire-logic/get-layout'
import { applyFilterModelToRow, columnValueGetter } from '../../modules/tables/utils'
import { getFieldType } from '../../modules/tables/mui-formatter'
import { Box } from '../box'
import { sortNodesWithUniqueDescendants } from '../../modules/tables/questionaire-logic/utils'
import { showSnackbar } from '../../helpers/snackbarManager'

import { QuestionFlowContainer } from './question-flow-container'
import { Question } from './types'

type QuestionFlowLogicContainerProps = {
	databaseLogic: V3ProjectTypes.ProjectChain['database_chain_logic'] | undefined
}

// const getQuestionParents = ({
// 															questionId,
// 															visitedNodes,
// 														}: {
// 	questionId: string
// 	visitedNodes?: Set<string>
// }): string[] => {
// 	if (!visitedNodes) {
// 		visitedNodes = new Set<string>()
// 	}
// 	if (visitedNodes.has(questionId)) {
// 		return []
// 	}
// 	visitedNodes.add(questionId)
// 	const parents = questions.filter((_ques) =>
// 		_ques.descendants.some((_desc) => _desc.row === questionId),
// 	)
// 	let parentIds = parents.map((_parent) => _parent._id)
// 	if (parents.length) {
// 		parentIds = [
// 			...parents
// 				.map((_parent) =>
// 					getQuestionParents({
// 						questionId: _parent._id,
// 						visitedNodes,
// 					}),
// 				)
// 				.flat(),
// 			...parentIds,
// 		]
// 	}
//
// 	return parentIds
// }

const convertQuestionTableIntoQuestions = ({
	questionTable,
	rowsAfterFilter,
	mappedColumns,
}: {
	questionTable: TableTypes.CangoTable
	rowsAfterFilter: TableTypes.Record[]
	mappedColumns: Map<string, TableTypes.Field>
}): Question[] => {
	const questionTableQuestionId = questionTable.principal_field ?? ''
	const questionTableOptionsId =
		questionTable.fields.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''

	const topsortedQuestionNodes = getLayout({
		rows: questionTable.records,
		questionColumnId: questionTableQuestionId,
		optionsColumnId: questionTableOptionsId,
	})

	const questionColumns = [...mappedColumns.values()].filter(
		(_col) => _col.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
	)
	const questionColumnIds = questionColumns.map((_col) => _col._id)
	return rowsAfterFilter.reduce((_questions: Question[], _row) => {
		const _rowQuestions = questionColumnIds.reduce(
			(_acc: { colId: string; questionId: string }[], _colId) => {
				if (!_row.data[_colId]) return _acc
				_acc.push({
					colId: _colId,
					questionId: _row.data[_colId] as string,
				})
				return _acc
			},
			[],
		)

		const constructedQuestions = _rowQuestions.reduce((_acc: Question[], _rowQuestion) => {
			const question = topsortedQuestionNodes.nodes.find(
				(_node) => _node.id === _rowQuestion.questionId,
			)
			if (!question) return _acc
			const questionRow = questionTable.records.find((_record) => _record._id === question.id)
			const column = mappedColumns.get(_rowQuestion.colId)
			_acc.push({
				_id: `${_row._id}-${_rowQuestion.colId}`,
				info:
					column?.question_card_information?.map((_colId) => {
						const mainTableColumn = mappedColumns.get(_colId)
						if (!mainTableColumn) return ''
						return `${mainTableColumn.name}: ${columnValueGetter(_row?.data[_colId], mainTableColumn, new Map(mainTableColumn.valueOptions?.map((_opt) => [_opt._id, _opt]) ?? []))}`
					}) ?? [],
				question: question.data.label,
				type: question.data.answerConfig.answerType,
				options: question.data.answerConfig.options,
				descendants: questionRow?.descendants ?? [],
				rowId: _row._id,
				columnId: _rowQuestion.colId,
				questionRowId: question.id,
				answer: _row.questionaireResponse?.[_rowQuestion.colId] ?? undefined,
				hierarchy:
					column?.question_card_information?.reduce((_acc: Record<string, any>, _colId) => {
						return {
							..._acc,
							[_colId]: _row.data[_colId],
						}
					}, {}) ?? {},
			})
			return _acc
		}, [])

		return [..._questions, ...constructedQuestions]
	}, [])
}

export const QuestionFlowLogicContainer: ComponentType<QuestionFlowLogicContainerProps> = ({
	databaseLogic,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { associatedQuestionaireId, table, mappedColumns, updateRowAnswers } =
		useContext(TableContext)
	const [isLoadingQuestionaire, setIsLoadingQuestionaire] = useState(false)
	const [questionaire, setQuestionaire] = useState<Question[]>([])
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
	const [stepHistory, setStepHistory] = useState<number[]>([])

	const findNextIndex = () => {
		const nextQuestion = questionaire[activeQuestionIndex + 1]
		if (!nextQuestion) return activeQuestionIndex
		return activeQuestionIndex + 1
	}

	const goToNextStep = () => {
		const nextIndex = findNextIndex()
		setStepHistory((prev) => [...prev, activeQuestionIndex])
		setActiveQuestionIndex(nextIndex)
	}

	const fetchTable = useCallback(async () => {
		setIsLoadingQuestionaire(true)
		try {
			const { table: questionTable } = await TablesSdk.getTable(
				import.meta.env.VITE_API as string,
				authHeaders,
				associatedQuestionaireId,
			)

			const rowsAfterFilter = [...(table?.records ?? [])].filter((_row) => {
				if (!databaseLogic?.filters) return true
				return applyFilterModelToRow(
					table?.fields.map((_field) => ({
						_id: _field._id,
						type: getFieldType(_field.type),
					})) ?? [],
					_row.data,
					databaseLogic?.filters,
				)
			})

			const questions = convertQuestionTableIntoQuestions({
				questionTable: questionTable,
				rowsAfterFilter,
				mappedColumns,
			})

			const questionColumn = table?.fields.find(
				({ type }) => type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
			)

			const sortedQuestions = sortNodesWithUniqueDescendants(
				questions,
				questionColumn?.question_card_information ?? [],
			)

			setQuestionaire(sortedQuestions)
		} catch (error) {
			showSnackbar('Failed to generate questionaire', { variant: 'error' })
		} finally {
			setIsLoadingQuestionaire(false)
		}
	}, [databaseLogic, table])

	const handleAnswerClick = (questionId: string, answer: TableTypes.QuestionaireResponse) => {
		if (questionaire.length === 0) return

		setQuestionaire((prev) => {
			const questionIndex = prev.findIndex((_question) => _question._id === questionId)
			if (questionIndex === -1) return prev
			prev[questionIndex].answer = answer
			return [...prev]
		})
		goToNextStep()
	}

	const handleSaveAnswers = () => {
		const answers = questionaire.reduce(
			(
				acc: {
					_id: string
					responses: { [columnId: string]: TableTypes.QuestionaireResponse }
				}[],
				_question,
			) => {
				const existingRowIndex = acc.findIndex((_row) => _row._id === _question.rowId)
				if (existingRowIndex === -1) {
					acc.push({
						_id: _question.rowId,
						responses: {
							[_question.columnId]: _question.answer,
						},
					})
				} else {
					acc[existingRowIndex].responses[_question.columnId] = _question.answer
				}
				return acc
			},
			[],
		)
		updateRowAnswers(answers)
	}

	useEffect(() => {
		fetchTable()
	}, [])

	if (isLoadingQuestionaire) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				<PulseLoader size={6} />
			</Box>
		)
	}

	if (!questionaire.length) {
		return (
			<Box display="flex" flexDirection="row" alignItems="center">
				No questions to display
			</Box>
		)
	}

	return (
		<QuestionFlowContainer
			questions={questionaire}
			onAnswerClick={handleAnswerClick}
			activeQuestion={questionaire[activeQuestionIndex]}
			activeQuestionIndex={activeQuestionIndex}
			goBack={() => {
				const lastStep = stepHistory.pop()
				if (lastStep !== undefined) {
					setActiveQuestionIndex(lastStep)
				}
			}}
			goForward={goToNextStep}
			onSave={handleSaveAnswers}
		/>
	)
}

import { List, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material'
import { ComponentType } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes } from '@cango-app/types'

import { colors } from 'src/theme/colors'

import { Box } from '../box'

type Props = {
	answerOptions: TableTypes.ListOption[]
	onAnswerClick: (answer: TableTypes.QuestionaireResponse) => void
	answer: TableTypes.QuestionaireResponse
}

export const AnswerManager: ComponentType<Props> = ({ answerOptions, onAnswerClick, answer }) => {
	const anyAnswerHasImage = answerOptions.find(
		({ image }) => image && !_isEmpty(image) && image.path,
	)
	return (
		<List
			dense
			component="div"
			role="list"
			sx={{
				paddingTop: 4,
			}}
		>
			{answerOptions.map(({ _id, label, image }) => {
				const labelledId = _id
				return (
					<ListItemButton
						key={_id}
						disableGutters
						onClick={() =>
							onAnswerClick({
								_id,
								label,
							})
						}
					>
						{anyAnswerHasImage ? (
							<Box width={150} height={150}>
								{image ? (
									<img
										src={image.path}
										alt={label}
										width={150}
										height={150}
										style={{
											objectFit: 'contain',
										}}
									/>
								) : (
									<Box
										width={150}
										height={150}
										sx={{
											backgroundColor: colors.neutral['10'],
										}}
									/>
								)}
							</Box>
						) : null}
						<ListItemIcon>
							<Checkbox
								disableRipple
								inputProps={{
									'aria-labelledby': labelledId,
								}}
								checked={_id === answer?._id}
							/>
						</ListItemIcon>
						<ListItemText id={labelledId} primary={label} />
					</ListItemButton>
				)
			})}
		</List>
	)
}

import { MenuItem, Menu, ListItemIcon } from '@mui/material'
import clipboard from 'clipboardy'
import _isEmpty from 'lodash/isEmpty'
import { ComponentType, useCallback, useContext, useMemo } from 'react'
import ListItemText from '@mui/material/ListItemText'
import { Check } from '@mui/icons-material'
import { TableTypes } from '@cango-app/types'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { TableContext } from 'src/providers/table-provider'

import { COPY_CLIPBOARD } from './utils'

type Props = {
	contextMenu: { mouseX: number; mouseY: number } | null
	handleClose: () => void
	cellData: { rowId: string; columnId: string } | undefined
}

export const RightClickMenuActions: ComponentType<Props> = ({
	contextMenu,
	handleClose,
	cellData = { rowId: '', columnId: '' },
}) => {
	const { table, mappedRecords, mappedColumns, updateTableListItem, linkedTable } =
		useContext(TableContext)
	const row = mappedRecords.get(cellData.rowId)
	const column = mappedColumns.get(cellData.columnId)
	const cellValue = useMemo(() => {
		if (!row || !column) {
			return ''
		}
		return String(row.data[column._id])
	}, [row, column])

	const copyToClipboard = useCallback(
		async (type: COPY_CLIPBOARD['type']) => {
			const selectedRow = table?.records.find(({ _id }) => cellData.rowId === _id)
			if (!selectedRow) {
				showSnackbar('Row not found', { variant: 'error' })
				return
			}
			switch (type) {
				case 'data': {
					const selectedCell = selectedRow.data[cellData.columnId]
					const dataInfo = JSON.stringify({
						type,
						data: selectedCell,
					})
					await clipboard.write(dataInfo)
					break
				}
				case 'row_calculation': {
					const selectedCell = selectedRow.calculations[cellData.columnId]
					const dataInfo = JSON.stringify({
						type,
						data: selectedCell,
					})
					await clipboard.write(dataInfo)
					break
				}
				case 'descendants': {
					if (!selectedRow.descendants) {
						return
					}
					const dataInfo = JSON.stringify({
						type,
						data: selectedRow.descendants,
					})
					await clipboard.write(dataInfo)
					break
				}
			}
		},
		[cellData, table?.records],
	)
	const hasCellCalculations = useMemo(() => {
		if (!cellData) return false
		const selectedRow = table?.records.find(({ _id }) => _id === cellData.rowId)
		if (!selectedRow) return false
		return _isEmpty(selectedRow.calculations)
	}, [cellData, table?.records])

	const hasCellDescendants = useMemo(() => {
		if (!cellData) return false
		const selectedRow = table?.records.find(({ _id }) => _id === cellData.rowId)
		if (!selectedRow) return false
		return _isEmpty(selectedRow.descendants)
	}, [cellData, table?.records])

	const handleQuestionGlobalToggle = async () => {
		if (!row || !table) {
			return
		}
		if (!linkedTable) {
			return
		}
		const globalQuestions = new Set(linkedTable.global_questions ?? [])
		if (globalQuestions.has(cellValue)) {
			globalQuestions.delete(cellValue)
		} else {
			globalQuestions.add(cellValue)
		}

		updateTableListItem(linkedTable._id, { global_questions: [...globalQuestions] })
		handleClose()
	}

	return (
		<Menu
			open={contextMenu !== null}
			onClose={handleClose}
			anchorReference="anchorPosition"
			anchorPosition={
				contextMenu !== null
					? {
							top: contextMenu.mouseY,
							left: contextMenu.mouseX,
						}
					: undefined
			}
		>
			<MenuItem
				onClick={() => copyToClipboard('data')}
				disabled={column?.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE}
			>
				Copy Cell
			</MenuItem>
			<MenuItem
				disabled={hasCellCalculations}
				onClick={() => {
					copyToClipboard('row_calculation')
					handleClose()
				}}
			>
				Copy calculation
			</MenuItem>
			<MenuItem
				disabled={hasCellDescendants}
				onClick={() => {
					copyToClipboard('descendants')
					handleClose()
				}}
			>
				Copy descendants
			</MenuItem>
			{column?.type === TableTypes.FieldType.QUESTIONAIRE_REFERENCE && (
				<MenuItem onClick={handleQuestionGlobalToggle}>
					{!!linkedTable?.global_questions?.includes(cellValue) && (
						<ListItemIcon>{<Check />}</ListItemIcon>
					)}
					<ListItemText>This answer is global</ListItemText>
				</MenuItem>
			)}
		</Menu>
	)
}
